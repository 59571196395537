body,
* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* For Amplify Authenticator */
:root {
  --primary-color: #361c4f;
  --primary-tint: #753d6e;
  --primary-shade: #753d6e;
  --secondary-color: #1e79a6;

  --amplify-primary-color: var(--primary-color);
  --amplify-primary-tint: var(--primary-tint);
  --amplify-primary-shade: var(--primary-shade);
}

.global-error {
  text-align: center;
  padding-top: 100px;
}
